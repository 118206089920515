import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import HomeOne from '../views/HomeOne.vue'
import Error from '../views/Error.vue'


Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/',
    name: 'HomeOne',
    component: HomeOne,
    meta: {title: 'SP VPN'}
  },
  // {
  //   path: '/service-details',
  //   name: 'ServiceDetails',
  //   component: ServiceDetails
  // },
  // {
  //   path: '/about-us',
  //   name: 'AboutUs',
  //   component: AboutUs
  // },
  // {
  //   path: '/shops',
  //   name: 'Shops',
  //   component: Shops
  // },
  // {
  //   path: '/shop-details',
  //   name: 'ShopDetails',
  //   component: ShopDetails
  // },
  // {
  //   path: '/news',
  //   name: 'News',
  //   component: News
  // },
  // {
  //   path: '/single-news',
  //   name: 'SingleNews',
  //   component: SingleNews
  // },
  // {
  //   path: '/contact',
  //   name: 'Contact',
  //   component: Contact
  // },
  {
    path: '/error',
    name: 'Error',
    component: Error
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // stop stop cache
  scrollBehavior(to) {

    setTimeout(() => {
      window.loadedFull = true
    }, 3500)

    if (to.hash) {
      console.log({hash: to.hash})
      setTimeout(() => {
        document.getElementById(to.hash.replace('#', '')).scrollIntoView({top: 20, behavior: 'smooth'})
      }, window.loadedFull ? 0 : 2500)
    } else {
      document.getElementById('app').scrollIntoView()
    }
  }
})

export default router
