<template>
  <!--====== PART START ======-->
  <header class="sp-vpn-header-area sp-vpn-sticky">
    <div class="container">
      <div class="header-nav-box">
        <div class="row align-items-center">
          <div class="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
            <div class="sp-vpn-logo-box">
              <a href="/">
                <img v-if="dark" src="@/assets/images/logo-7.png" alt="sp-vpn"/>
                <img v-else src="@/assets/images/logo.png" alt="sp-vpn"/>
              </a>
            </div>
          </div>
          <div class="col-lg-6 col-md-1 col-sm-1 order-3 order-sm-2">
            <div class="sp-vpn-header-main-menu">
              <nav-items :menuItems="menuItems" :nasted="nasted"/>
            </div>
          </div>
          <div class="col-lg-4 col-md-7 col-sm-6 col-6 order-2 order-sm-3">
            <div class="sp-vpn-btn-box" style="text-align: right">
              <template v-if="this.$store.state.enableRtl">
                <span
                    v-if="this.$store.state.rtl"
                    class="align-content-center mr-10"
                    @click="changeLang()"
                >
                  LTR
                </span>
                <span
                    v-else
                    class="align-content-center mr-10"
                    @click="changeLang()"
                >
                  RTL
                </span>
              </template>
              <template v-if="enableDark">
                <span @click="toggoleMode" v-if="dark" class="dark__btn__sun">
                  <svg
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                    ></path>
                  </svg>
                </span>
                <span @click="toggoleMode" v-else class="dark__btn__mon">
                  <svg
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                    ></path>
                  </svg>
                </span>
              </template>

<!--              <a class="login-btn" href="#"><i class="fal fa-user"></i> Login</a>-->

<!--              <vue-telegram-login-->
<!--                  v-if="showTg"-->
<!--                  mode="callback"-->
<!--                  size="large"-->
<!--                  requestAccess="write"-->
<!--                  telegram-login="sp_vpn_bot"-->
<!--                  radius="6"-->
<!--                  @callback="onTelegramAuth" />-->
<!--              <div v-else>-->
<!--                {{tgName}} <img :src="tgPhoto" style="height: 100%; width: auto; border-radius: 100%"/>-->
<!--              </div>-->
<!--              <script async src="https://telegram.org/js/telegram-widget.js?22" data-telegram-login="sp_vpn_bot" data-size="medium" data-onauth="onTelegramAuth(user)" data-request-access="write"></script>-->
              <a class="main-btn ml-30" href="https://t.me/sp_vpn_bot?start=from-siteHeaderBtn"><i
                  class="fab fa-telegram-plane"></i> Перейти в бота</a>
              <div class="toggle-btn ml-30 canvas_open d-lg-none d-block">
                <i class="fa fa-bars" @click="showSidebar"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <!--====== PART ENDS ======-->
</template>

<script>
import NavItems from "../NavItems.vue"

export default {
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
    nasted: {
      type: Boolean,
      default: true,
    },
    dark: {
      type: Boolean,
    },
    enableDark: {
      type: Boolean,
    },
  },
  components: {NavItems},
  data() {
    return {
      showTg: false,
      tgPhoto: 'https://t.me/i/userpic/320/60j1U4wNMZ5sUj2RlLTmFCa45M3WzmPRSa1CaS0WGwM.jpg',
      tgName: 'Denis'
    }
  },
  mounted() {
    document.addEventListener("scroll", this.stickMenu)

    const tgInfo = JSON.parse(localStorage.getItem('telegram') ?? '{}')

    if(!tgInfo.id) {
      this.showTg = true
    } else {
      this.setTg(tgInfo)
    }
  },
  methods: {
    onTelegramAuth (user) {
      // gets user as an input
      // id, first_name, last_name, username,
      // photo_url, auth_date and hash
      localStorage.setItem('telegram', JSON.stringify(user));
      this.setTg(user)
    },
    setTg(tgInfo) {
      this.tgName = tgInfo.first_name ?? tgInfo.username ?? tgInfo.id;
      this.tgPhoto = tgInfo.photo_url;
    },
    changeLang() {
      this.$store.dispatch("rtlHandler")
    },
    toggoleMode(e) {
      this.$emit("changMode", e)
    },
    showSidebar(e) {
      this.$emit("toggleSidebar", e)
    },
    stickMenu() {
      const result = document.querySelector(".sp-vpn-sticky")
      if (
          document.body.scrollTop > 100 ||
          document.documentElement.scrollTop > 100
      ) {
        result.classList.add("sticky")
      } else {
        result.classList.remove("sticky")
      }
    },
  },
}
</script>

<style>
</style>
