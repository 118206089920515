<template>
  <!--====== sp-vpn FOOTER PART START ======-->

  <section class="sp-vpn-footer-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="footer-about-widget">
            <div class="logo">
              <a href="#"><img src="@/assets/images/logo.png" alt="" /></a>
            </div>
            <p>
              SP VPN
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="footer-navigation">
            <h4 class="title">Поддержка</h4>
            <ul>
              <li><a href="https://docs.google.com/document/d/10hv-1zOm6HMgudROMF5Za3hIj_p14CcYcD4beYOS7qw/edit" target="_blank">Правила возврата</a></li>
              <li><a href="https://docs.google.com/document/d/1o57HHN41-zzOz4zOB0ZaiY56AQl-kLjJ362vR1QWXVQ/edit" target="_blank">Оферта</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="footer-widget-info">
            <h4 class="title">Информация</h4>
            <ul>
              <li>
                ИП Сидоров Денис
              </li>
              <li>
                ОГРНИП:318784700135482
              </li>
              <li>
                ИНН:511201452344
              </li>
              <li>
                <i class="fal fa-map-marker-alt"></i> 197375, Санкт-Петербург, Вербная12к1
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-md-6" id="contacts">
          <div class="footer-widget-info">
            <h4 class="title">Контакты</h4>
            <ul>
              <li>
                <a href="https://t.me/njg_ss"
                ><i class="fab fa-telegram-plane"></i>@njg_ss</a
                >
              </li>
              <li>
                <a href="mailto:ijoy14@yandex.ru"
                ><i class="fal fa-envelope"></i>ijoy14@yandex.ru</a
                >
              </li>
              <li>
                <a href="tel:+79995293121"><i class="fal fa-phone"></i> +7(999) 529-31-21</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== sp-vpn FOOTER PART ENDS ======-->
</template>

<script>
export default {
  data() {
    return {
      year: (new Date()).getFullYear()
    }
  }
};
</script>

<style>
</style>
