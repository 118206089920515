<template>
  <!--====== sp-vpn FAQ PART START ======-->

  <section class="sp-vpn-faq-area pb-95">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="sp-vpn-section-title text-center">
            <h3 class="sp-vpn-title">{{ title }}</h3>
            <p>{{ description }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6" v-for="questionChunk in questions" v-bind:key="questionChunk[0].id">
          <div class="faq-accordion wow fadeInRight mt-30" data-wow-duration="1500ms" v-for="question in questionChunk" v-bind:key="question.id">
            <div class="accrodion-grp animated fadeIn faq-accrodion wow" data-wow-duration="1500ms"
                 data-grp-name="faq-accrodion">
              <div class="accrodion" @click.prevent="openQuestion(question.id)" :class="[showQuestion === question.id?'active':'']">
                <div class="accrodion-inner">
                  <div class="accrodion-title">
                    <h4>{{question.q}}</h4>
                  </div>
                  <div class="accrodion-content" :style="[showQuestion === question.id?{'display':'block'}:{'display':'none'}]">
                    <div class="inner">
                      <div v-html="question.a"></div>
                    </div><!-- /.inner -->
                  </div>
                </div><!-- /.accrodion-inner -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="faq-text text-center pt-40">
            <p>Не нашли ответ на свой вопрос? <a href="https://t.me/njg_ss" target="_blank"> Напишите нам</a></p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== sp-vpn FAQ PART ENDS ======-->
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    }
  },
  data() {
    const questions = [
      {
        id: 2,
        q: 'Зачем мне покупать впн, если есть бесплатные?',
        a: 'Бесплатные VPN могут продавать данные пользователей и вызывать проблемы с доступом к сайтам, а также увеличивать риск мошенничества.'
      },
      {
        id: 1,
        q: 'Какой сервер мне выбрать?',
        a: 'Если вы испытываете проблемы с доступом к российским сервисам - используйте РФ' +
            '<br>' +
            '<br>' +
            'Если для вас не важно определяемое ваше положение - используйте любой'
      },
      {
        id: 3,
        q: 'Почему лучше покупать ваш впн, а не другой?',
        a: 'Наш VPN не ограничивает скорость интернета. А также у нас гибкая система тарификации с весьма демократичными ценами в сравнении с другими VPN'
      },
      {
        id: 4,
        q: 'Как отсканировать QR-код если я с телефона?',
        a: 'Для этого вам понадобится второе устройство, на котором будет отображен QR-код'
      },
      {
        id: 5,
        q: 'Какие формы оплаты есть?',
        a: 'Вы можете выбрать оплату РФ картой, или картой Visa/Mastercard по всему миру'
      },
      {
        id: 6,
        q: 'Что мне нужно сделать, чтобы он работал?',
        a: 'Вам достаточно выбрать подходящий тариф, и выбрать сервер. Далее бот проведет вас по подробным шагам установки, их не много :)'
      },
    ];

    const chunkSize = 2;
    const chunks = [];
    for (let i = 0; i < questions.length; i += chunkSize) {
      console.log(questions.slice(i, i + chunkSize))
      chunks.push([...questions.slice(i, i + chunkSize)])
      // do whatever
    }

    return {
      showQuestion: 2,
      questions: chunks
    }
  },
  methods: {
    openQuestion(value) {
      this.showQuestion = this.showQuestion === value ? 0 : value
    }
  }

}
</script>

<style>

</style>
